import { type ChangeEvent, useContext } from 'react'
import { useForm, Controller } from 'react-hook-form'

import { StringsContext } from '@lib/strings-context'

import Accordion from '@components/accordion'
import TextArea from '@components/textarea'

interface CommentFieldProps {
  onChange?: (value: string) => void
}

interface CommentFieldValues {
  comment: string
}

const CommentField = ({ onChange }: CommentFieldProps) => {
  const strings = useContext(StringsContext)
  const { control } = useForm<CommentFieldValues>()

  const handleChange = (event: ChangeEvent) => {
    const inputEvent = event as ChangeEvent<HTMLInputElement>

    if (onChange) {
      onChange(inputEvent.target.value)
    }
  }

  return (
    <Accordion
      id="cart-comment"
      title={strings.cartCommentSectionTitle}
      borderBottom
      hideOverflow
    >
      <Controller
        name="comment"
        control={control}
        render={({ field: { name, ref, value, onChange, onBlur } }) => (
          <TextArea
            value={value}
            placeholder={strings.cartCommentInputPlaceholder}
            formRegister={{
              name,
              ref,
              onBlur: async () => onBlur(),
              onChange: async (event: ChangeEvent) => {
                onChange(event)
                handleChange(event)
              },
            }}
          />
        )}
      />
    </Accordion>
  )
}
export default CommentField
