import { type ReactNode, createContext, useMemo } from 'react'

import {
  type SanitySiteFragment,
  type SanityStrings,
} from '@data/sanity/queries/types/site'

type StringsContextProps = SanityStrings

const emptySiteStrings: StringsContextProps = {
  loadingPageTitle: '',
  searchingText: '',
  noResultsText: '',
  languageSwitch: '',
  cartLink: '',
  featuredProducts: '',
  skipToContent: '',
  goHomeLabel: '',
  mobileMenuLabel: '',
  productOptionsHeading: '',
  productInStock: '',
  productLowStock: '',
  productOutOfStock: '',
  productPriceLabel: '',
  productSku: '',
  productColorOptionLabel: '',
  productDiscountText: '',
  productIncreaseQuantity: '',
  productDecreaseQuantity: '',
  productEnterQuantity: '',
  productNotLoggedIn: '',
  combinedListingLoadingText: '',
  combinedListingErrorText: '',
  combinedListingFrame: '',
  combinedListingFrameColor: '',
  combinedListingSize: '',
  combinedListingGlass: '',
  combinedListingNoGlass: '',
  combinedListingBackboard: '',
  combinedListingNoBackboard: '',
  combinedListingPassepartout: '',
  combinedListingPassepartoutHoleSize: '',
  combinedListingPassepartoutHoleSizeTooltip: '',
  combinedListingPassepartoutHoleSizePlaceholder: '',
  combinedListingNoPassepartout: '',
  combinedListingSpacer: '',
  combinedListingNoSpacer: '',
  combinedListingEmptyFrame: '',
  combinedListingPreviewSettingsTitle: '',
  combinedListingPreviewSettingsUploadLabel: '',
  combinedListingPreviewSettingsUploadDescription: '',
  combinedListingPreviewSettingsUploadError: '',
  combinedListingPreviewSettingsColorLabel: '',
  cartTitle: '',
  cartClose: '',
  cartEmpty: '',
  cartVatSectionTitle: '',
  cartVatIdInputPlaceholder: '',
  cartVatIdError: '',
  cartInvalidVatIdError: '',
  cartCommentSectionTitle: '',
  cartCommentInputPlaceholder: '',
  cartSubtotal: '',
  cartDiscount: '',
  cartShipping: '',
  cartTotal: '',
  cartSubmit: '',
  cartAddToCartErrorMessage: [],
  carouselCounterText: '',
  carouselLeftArrowLabel: '',
  carouselRightArrowLabel: '',
  carouselDotLabel: '',
  buttonAddToCart: '',
  buttonRemove: '',
  buttonAccept: '',
  buttonLearnMore: '',
  buttonTryAgain: '',
  buttonLoadMore: '',
  buttonUnavailable: '',
  buttonWaiting: '',
  buttonLoading: '',
  buttonCreatingProduct: '',
  buttonAdding: '',
  buttonUpdating: '',
  buttonSubmitting: '',
  buttonEdit: '',
  buttonDelete: '',
  buttonCancel: '',
  buttonSignup: '',
  buttonLogin: '',
  buttonSeeMore: '',
  buttonSeeOptions: '',
  emailAddress: '',
  emailAddressPlaceholder: '',
  emailMissing: '',
  emailInvalid: '',
  firstName: '',
  firstNamePlaceholder: '',
  firstNameMissing: '',
  lastName: '',
  lastNamePlaceholder: '',
  lastNameMissing: '',
  fullName: '',
  fullNamePlaceholder: '',
  fullNameMissing: '',
  phoneNumber: '',
  phoneNumberPlaceholder: '',
  phoneNumberMissing: '',
  phoneNumberInvalid: '',
  company: '',
  companyPlaceholder: '',
  message: '',
  messagePlaceholder: '',
  productWaitlistSubmit: '',
  productWaitlistSuccess: '',
  productWaitlistError: '',
  blogPostReadMore: '',
  blogPostBy: '',
  teamMemberCardLinkedIn: '',
  teamMemberCardBlogArticles: '',
}

export const StringsContext =
  createContext<StringsContextProps>(emptySiteStrings)

interface StringsContextProviderProps {
  site: SanitySiteFragment
  children: ReactNode
}

export const StringsContextProvider = ({
  site,
  children,
}: StringsContextProviderProps) => {
  const strings = useMemo(
    () => ({
      ...emptySiteStrings,
      ...site.strings,
    }),
    [site.strings]
  )

  return (
    <StringsContext.Provider value={strings}>
      {children}
    </StringsContext.Provider>
  )
}
